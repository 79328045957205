import React from "react"
import styled from "styled-components"
import Layout from "@utils/layout"
import SEO from "@utils/seo"
import Container from "@atoms/container"

const ImpressumPage = () => {
  return (
    <Layout>
      <SEO title="Data policy" />
      <ContentContainer content>
        Datenschutzerklärung 1. Datenschutz auf einen Blick Allgemeine Hinweise
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung. Datenerfassung auf dieser Website Wer ist
        verantwortlich für die Datenerfassung auf dieser Website? Die
        Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
        Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
        Wie erfassen wir Ihre Daten? Ihre Daten werden zum einen dadurch
        erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
        Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten
        werden automatisch beim Besuch der Website durch unsere IT-Systeme
        erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
        Daten erfolgt automatisch, sobald Sie diese Website betreten. Wofür
        nutzen wir Ihre Daten? Ein Teil der Daten wird erhoben, um eine
        fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
        können zur Analyse Ihres Nutzerverhaltens verwendet werden. Welche
        Rechte haben Sie bezüglich Ihrer Daten? Sie haben jederzeit das Recht
        unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
        gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem
        ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des
        Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
        Aufsichtsbehörde zu. Außerdem haben Sie das Recht, unter bestimmten
        Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen. Details hierzu entnehmen Sie der
        Datenschutzerklärung unter „Recht auf Einschränkung der Verarbeitung“.
        Analyse-Tools und Tools von Drittanbietern Beim Besuch dieser Website
        kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht
        vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse
        Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten
        kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse
        widersprechen oder sie durch die Nichtbenutzung bestimmter Tools
        verhindern. Detaillierte Informationen zu diesen Tools und über Ihre
        Widerspruchsmöglichkeiten finden Sie in der folgenden
        Datenschutzerklärung. 2. Hosting und Content Delivery Networks (CDN)
        Externes Hosting Diese Website wird bei einem externen Dienstleister
        gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website
        erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei
        kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
        Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
        Webseitenzugriffe und sonstige Daten, die über eine Website generiert
        werden, handeln. Der Einsatz des Hosters erfolgt zum Zwecke der
        Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden
        (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen
        und effizienten Bereitstellung unseres Online-Angebots durch einen
        professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Unser Hoster wird
        Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
        Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf
        diese Daten befolgen. 3. Allgemeine Hinweise und Pflichtinformationen
        Datenschutz Die Betreiber dieser Seiten nehmen den Schutz Ihrer
        persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen
        Daten vertraulich und entsprechend der gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
        diese Website benutzen, werden verschiedene personenbezogene Daten
        erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
        identifiziert werden können. Die vorliegende Datenschutzerklärung
        erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
        erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
        darauf hin, dass die Datenübertragung im Internet (z. B. bei der
        Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich. Hinweis zur verantwortlichen Stelle Die verantwortliche Stelle
        für die Datenverarbeitung auf dieser Website ist: Johannes König Meraner
        Straße 17 16341 Panketal E-Mail: johannes@recruiting-nerds.de
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet. Widerruf Ihrer Einwilligung zur Datenverarbeitung
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt. Widerspruchsrecht gegen die Datenerhebung
        in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO) WENN DIE
        DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
        ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER
        BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN
        IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
        SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
        BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
        EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
        DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
        PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
        DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        Beschwerderecht bei der zuständigen Aufsichtsbehörde Im Falle von
        Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei
        einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
        gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
        mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
        anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        Recht auf Datenübertragbarkeit Sie haben das Recht, Daten, die wir auf
        Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
        automatisiert verarbeiten, an sich oder an einen Dritten in einem
        gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
        direkte Übertragung der Daten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist. SSL- bzw.
        TLS-Verschlüsselung Diese Seite nutzt aus Sicherheitsgründen und zum
        Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
        Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden,
        eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
        erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf
        „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
        Auskunft, Löschung und Berichtigung Sie haben im Rahmen der geltenden
        gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
        Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft
        und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
        Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
        zum Thema personenbezogene Daten können Sie sich jederzeit unter der im
        Impressum angegebenen Adresse an uns wenden. Recht auf Einschränkung der
        Verarbeitung Sie haben das Recht, die Einschränkung der Verarbeitung
        Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich
        jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das
        Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
        Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
        überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Wenn die Verarbeitung Ihrer personenbezogenen Daten
        unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
        Einschränkung der Datenverarbeitung verlangen. Wenn wir Ihre
        personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
        Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
        benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie einen
        Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
        Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
        Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie
        das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen. Wenn Sie die Verarbeitung Ihrer personenbezogenen
        Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
        abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
        oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Europäischen Union oder eines
        Mitgliedstaats verarbeitet werden. 4. Datenerfassung auf dieser Website
        MailChimp Diese Website nutzt die Dienste von MailChimp für den Versand
        von Newslettern. Anbieter ist die Rocket Science Group LLC, 675 Ponce De
        Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA. MailChimp ist ein
        Dienst, mit dem u.a. der Versand von Newslettern organisiert und
        analysiert werden kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs
        eingeben (z. B. E-Mail-Adresse), werden diese auf den Servern von
        MailChimp in den USA gespeichert. MailChimp verfügt über eine
        Zertifizierung nach dem „EU-US-Privacy-Shield“. Der „Privacy-Shield“ ist
        ein Übereinkommen zwischen der Europäischen Union (EU) und den USA, das
        die Einhaltung europäischer Datenschutzstandards in den USA
        gewährleisten soll. Mit Hilfe von MailChimp können wir unsere
        Newsletterkampagnen analysieren. Wenn Sie eine mit MailChimp versandte
        E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei (sog.
        web-beacon) mit den Servern von MailChimp in den USA. So kann
        festgestellt werden, ob eine Newsletter-Nachricht geöffnet und welche
        Links ggf. angeklickt wurden. Außerdem werden technische Informationen
        erfasst (z. B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und
        Betriebssystem). Diese Informationen können nicht dem jeweiligen
        Newsletter-Empfänger zugeordnet werden. Sie dienen ausschließlich der
        statistischen Analyse von Newsletterkampagnen. Die Ergebnisse dieser
        Analysen können genutzt werden, um künftige Newsletter besser an die
        Interessen der Empfänger anzupassen. Wenn Sie keine Analyse durch
        MailChimp wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen
        wir in jeder Newsletternachricht einen entsprechenden Link zur
        Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf der
        Website abbestellen. Die Datenverarbeitung erfolgt auf Grundlage Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
        jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die
        Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
        vom Widerruf unberührt. Die von Ihnen zum Zwecke des Newsletter-Bezugs
        bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus
        dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
        und nach der Abbestellung des Newsletters aus der
        Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken bei uns
        gespeichert wurden bleiben hiervon unberührt. Nach Ihrer Austragung aus
        der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
        Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um
        künftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur
        für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt.
        Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
        Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
        (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
        Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können
        der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
        Interesse überwiegen. Näheres entnehmen Sie den Datenschutzbestimmungen
        von MailChimp unter: https://mailchimp.com/legal/terms/. Abschluss eines
        Data-Processing-Agreements Wir haben ein sog.
        „Data-Processing-Agreement“ mit MailChimp abgeschlossen, in dem wir
        MailChimp verpflichten, die Daten unserer Kunden zu schützen und sie
        nicht an Dritte weiterzugeben. Cookies Unsere Internetseiten verwenden
        so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf
        Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für
        die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
        Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach
        Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf
        Ihrem Endgerät gespeichert bis Sie diese selbst löschen oder eine
        automatische Lösung durch Ihren Webbrowser erfolgt. Teilweise können
        auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden,
        wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen
        uns oder Ihnen die Nutzung bestimmter Dienstleistungen des
        Drittunternehmens (z.B. Cookies zur Abwicklung von
        Zahlungsdienstleistungen). Cookies haben verschiedene Funktionen.
        Zahlreiche Cookies sind technisch notwendig, da bestimmte
        Webseitenfunktionen ohne diese nicht funktionieren würden (z.B. die
        Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen
        dazu das Nutzerverhalten zu auszuwerten oder Werbung anzuzeigen.
        Technisch notwendige Cookies (z.B. Warenkorb-Cookies) werden auf
        Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Wir haben ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung unserer Dienste. Andere
        Cookies werden nur mit Ihrer Einwilligung auf Grundlage von Art. 6 Abs.
        1 lit. a DSGVO gespeichert. Die Einwilligung ist jederzeit für die
        Zukunft widerrufbar. Sie können Ihren Browser so einstellen, dass Sie
        über das Setzen von Cookies informiert werden und Cookies nur im
        Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
        generell ausschließen sowie das automatische Löschen der Cookies beim
        Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
        kann die Funktionalität dieser Website eingeschränkt sein. Soweit
        Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden,
        werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert
        informieren und ggf. eine Einwilligung abfragen. Server-Log-Dateien Der
        Provider der Seiten erhebt und speichert automatisch Informationen in so
        genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind: Browsertyp und Browserversion verwendetes
        Betriebssystem Referrer URL Hostname des zugreifenden Rechners Uhrzeit
        der Serveranfrage IP-Adresse Eine Zusammenführung dieser Daten mit
        anderen Datenquellen wird nicht vorgenommen. Die Erfassung dieser Daten
        erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der technisch
        fehlerfreien Darstellung und der Optimierung seiner Website – hierzu
        müssen die Server-Log-Files erfasst werden. Kommentarfunktion auf dieser
        Website Für die Kommentarfunktion auf dieser Seite werden neben Ihrem
        Kommentar auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre
        E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte
        Nutzername gespeichert. Speicherung der IP-Adresse Unsere
        Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare
        verfassen. Da wir Kommentare auf dieser Website nicht vor der
        Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
        Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser
        vorgehen zu können. Abonnieren von Kommentaren Als Nutzer der Seite
        können Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine
        Bestätigungsemail, um zu prüfen, ob Sie der Inhaber der angegebenen
        E-Mail-Adresse sind. Sie können diese Funktion jederzeit über einen Link
        in den Info-Mails abbestellen. Die im Rahmen des Abonnierens von
        Kommentaren eingegebenen Daten werden in diesem Fall gelöscht; wenn Sie
        diese Daten für andere Zwecke und an anderer Stelle (z. B.
        Newsletterbestellung) an uns übermittelt haben, verbleiben die jedoch
        bei uns. Speicherdauer der Kommentare Die Kommentare und die damit
        verbundenen Daten (z. B. IP-Adresse) werden gespeichert und verbleiben
        auf dieser Website, bis der kommentierte Inhalt vollständig gelöscht
        wurde oder die Kommentare aus rechtlichen Gründen gelöscht werden müssen
        (z. B. beleidigende Kommentare). Rechtsgrundlage Die Speicherung der
        Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
        a DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit
        widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
        Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt
        vom Widerruf unberührt. Analyse- und Trackingtools Wir setzen auf
        unserer Webseite die nachfolgend aufgelisteten Analyse- und
        Trackingtools ein. Diese dienen dazu, die fortlaufende Optimierung
        unserer Webseite sicherzustellen und diese bedarfsgerecht zu gestalten.
        Diese Interessen sind als berechtigt im Sinne des Art. 6 Abs. 1 S. 1
        lit. f DSGVO anzusehen. Die jeweiligen Datenverarbeitungszwecke und
        Datenkategorien sind aus den entsprechenden Tools zu entnehmen. Google
        Analytics Wir benutzen auf unserer Webseite Google Analytics, einen
        Webanalysedienst der Google LLC (https://www.google.de/ intl/de/about/)
        (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden
        „Google“). Google Analytics verwendet in diesem Zusammenhang Cookies.
        Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser
        Webseite wie Name und Version des verwendeten Browsers Betriebssystem
        Ihres Rechners Webseite, von der aus der Zugriff erfolgt (Referrer-URL)
        IP-Adresse des anfragenden Rechners Uhrzeit der Serveranfrage werden in
        der Regel an einen Server von Google in den USA übertragen und dort
        gespeichert. Da wir die IP-Anonymisierung auf unserer Webseite aktiviert
        haben, wird Ihre IP-Adresse von Google jedoch innerhalb von
        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
        in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google
        diese Informationen benutzen, um Ihre Nutzung unserer Webseite
        auszuwerten, um Reports über die Webseiten-Aktivitäten zusammenzustellen
        und um weitere mit der Webseiten-Nutzung und der Internetnutzung
        verbundene Dienstleistungen uns gegenüber zu erbringen. Die im Rahmen
        von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
        nicht mit anderen Daten von Google zusammengeführt. Sie können die
        Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
        Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie
        in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer
        Webseite vollumfänglich werden nutzen können. Sie können darüber hinaus
        die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
        Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
        Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter
        dem folgenden Link verfügbare Browser-Plugin herunterladen und
        installieren http://tools.google.com/dlpage/gaoptout?hl=de Sie können
        die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie
        auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die
        Erfassung Ihrer Daten bei zukünftigen Besuchen unserer Webseite
        verhindert: Deaktiviere Google Analytics: Disable Google Analytics
      </ContentContainer>
    </Layout>
  )
}

export default ImpressumPage

const ContentContainer = styled(Container)`
  &:nth-of-type(odd) {
    direction: rtl;

    div {
      direction: ltr;
    }
  }
`

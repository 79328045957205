import React from "react"
import styled from "styled-components"

const Logo = () => {
  return (
    <StarterLogo
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 327.6 327.6"
    >
      <path
        fill="#4A566E"
        d="M30,67.8h63.2v-9.6c0-12,9.6-21.6,21.6-21.6h98c12,0,21.6,9.6,21.6,21.6v9.6h63.2
        c16.4,0,30,13.6,30,30V261c0,16.4-13.6,30-30,30H30c-16.4,0-30-13.6-30-30V97.8C0,81,13.6,67.8,30,67.8z"
      ></path>
      <path
        fill="#4A566E"
        fillRule="evenodd"
        d="M209.2,134.2c-11.6-11.6-27.6-18.4-45.2-18.4s-33.6,7.2-45.2,18.8c-11.6,11.6-18.8,27.6-18.8,45.2
        s7.2,33.6,18.8,45.2s27.6,18.8,45.2,18.8s33.6-7.2,45.2-18.8c11.6-11.6,18.8-27.6,18.8-45.2C227.6,161.8,220.4,145.8,209.2,134.2z
         M164,99c22,0,42.4,8.8,56.8,23.6c14.4,14.4,23.6,34.8,23.6,56.8s-8.8,42.4-23.6,56.8c-14.4,14.4-34.8,23.6-56.8,23.6
        s-42.4-8.8-56.8-23.6c-14.4-14.4-23.6-34.8-23.6-56.8s8.8-42.4,23.6-56.8C121.6,107.8,142,99,164,99z"
        clipRule="evenodd"
      ></path>
    </StarterLogo>
  )
}

export default Logo

const StarterLogo = styled.svg`
  height: 40px;
  width: 250px;
`
